import moment from 'moment';
import useApi from './api';
import { captureException } from './sentry';
import { ref } from 'vue';

type FeatureKey = (typeof FEATURES)[keyof typeof FEATURES];

const cachedAccess = ref<
  | {
    features: FeatureKey[];
    user_id: number;
    project_id: undefined | number;
    timestamp: number;
  }
  | undefined
>();
let isLoading = false;

/**
 * Note: Hub Only. Gather does not know what project it is in...
 */
function getCurrentProjectId() {
  const id = localStorage.getItem('project_id') || undefined;
  return id ? parseInt(id) : undefined;
}

function getCurrentUserId() {
  const loginData = localStorage.getItem('auth_user')
  if (!loginData) {
    return undefined;
  }
  const user = JSON.parse(loginData)?.user;
  return user?.user_id;
}

function parseCachedAccess() {
  const accessJson = localStorage.getItem('access_list');
  if (accessJson) {
    try {
      const storageAccessList = JSON.parse(accessJson);
      if (storageAccessList) {
        if (!('features' in storageAccessList)) {
          throw new Error('Invalid access list');
        }
        cachedAccess.value = storageAccessList;
      }
    } catch (e) {
      console.error('failed to parse features', e);
      clearAccessCache();
    }
  }
}

function clearAccessCache() {
  console.warn('Clearing feature cache');
  cachedAccess.value = undefined;
  localStorage.removeItem('access_list');
}

function setProjectId(project_id?: number) {
  if (cachedAccess.value && cachedAccess.value.project_id !== project_id) {
    cachedAccess.value.features = [];
    loadFeatureList();
  }
}

function setAccessList(
  newAccessList: FeatureKey[],
  userId: number,
  projectId?: number
) {
  newAccessList = newAccessList || [];
  if (!Array.isArray(newAccessList)) {
    newAccessList = Object.values(newAccessList);
  }
  cachedAccess.value = {
    features: [...newAccessList],
    user_id: userId,
    timestamp: moment().unix(),
    project_id: projectId,
  };
  localStorage.setItem('access_list', JSON.stringify(cachedAccess.value));
}

function getAccessList(): FeatureKey[] | undefined {
  return cachedAccess.value?.features;
}

function pretifySlug(slug: string, char = '-', joinChar = ' ') {
  const finalParts: Array<string> = [];
  const parts = slug.split(char);

  for (let i in parts) {
    if (parts.length <= 2) {
      finalParts.push(parts[i].toUpperCase());
      continue;
    }
    finalParts.push(parts[i].charAt(0).toUpperCase() + parts[i].slice(1));
  }

  return finalParts.join(joinChar);
}

function pretifyScopedFeatureSlug(slug: string) {
  return pretifySlug(pretifySlug(slug), '.', ' - ');
}

async function loadFeatureList() {
  if (isLoading) {
    return;
  }
  isLoading = true;
  const projectId = getCurrentProjectId();
  await useApi()
    .get('/feature/access-list', {
      params: {
        project_id: projectId,
      },
    })
    .then((response) => {
      const userId = getCurrentUserId();
      if (!userId) {
        throw new Error('No user id found');
      }
      setAccessList(response.data.access_list, userId, projectId);
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => (isLoading = false));
}

function hasAccess(featureKey: FeatureKey): boolean | null {
  if (!cachedAccess.value) {
    parseCachedAccess();
    if (cachedAccess.value) {
      return hasAccess(featureKey);
    }
    loadFeatureList();
    return null;
  }
  if (isCacheOld()) {
    loadFeatureList();
  }

  return !!cachedAccess.value?.features?.includes(featureKey);
}

function awaitAccess(featureKey: FeatureKey): Promise<boolean> {
  return new Promise((resolve) => {
    const has = hasAccess(featureKey);
    if (has) {
      resolve(true);
      return;
    }
    if (!isLoading) {
      resolve(!!has);
      return;
    }

    const interval = setInterval(() => {
      console.debug('Waiting for feature flags to load...', featureKey);
      if (!isLoading) {
        clearTimeout(timeout);
        clearInterval(interval);
        resolve(!!hasAccess(featureKey));
      }
    }, 100);
    const timeout = setTimeout(() => {
      clearInterval(interval);
      resolve(false);
      captureException(
        new Error('Awaiting features timed out! Feature:' + featureKey)
      );
    }, 10000);
  });
}

function isCacheOld() {
  if (!cachedAccess.value) {
    return true;
  }
  const currentProjectId = getCurrentProjectId();
  if (
    currentProjectId &&
    cachedAccess.value.project_id &&
    cachedAccess.value.project_id !== getCurrentProjectId()
  ) {
    clearAccessCache();
    return true;
  }
  if (cachedAccess.value.user_id !== getCurrentUserId()) {
    clearAccessCache();
    return true;
  }
  const lastCachedAt = moment.unix(cachedAccess.value.timestamp);
  return lastCachedAt && moment().isBefore(lastCachedAt.subtract(1, 'hours'));
}

const FEATURES = {
  PROJECT_TEAMS: 'project-teams',
  PROJECT_TEAMS_EXTERNAL_USERS: 'project-teams.external-users',
  LIVE_CHAT: 'live-chat',
  VISUAL_REPORTS: 'visual-reports',
  ENVIRO_MODULE: 'enviro-module',
  FILE_BROWSER: 'file-browser',
  FILE_BROWSER_VERSIONS: 'file-browser.version-control',
  FILE_BROWSER_UNLIMITED_VERSIONS: 'file-browser.unlimited-versions',
  AUTO_DOCS_WORD: 'auto-docs-word',
  AUTO_DOCS_EXCEL: 'auto-docs-excel',
  AUTO_DOCS_EXCEL_EDIT: 'auto-docs-excel.edit',
  MAPS_HEATMAPS: 'maps.heatmaps',
  MAPS_INTEGRATIONS_GATHER_DATA: 'maps.integrations.gather-data',
  MAPS_INTEGRATIONS_SERVICE_LAYER: 'maps.integrations.service-layer',
  MAPS_ATTRIBUTES_TABLE: 'maps.attributes-table',
  MAPS_FIGURE_STYLING_RULES: 'maps.figure-styling-rules',
  MAPS_SHOW_MAP_IN_CALL_OUT: 'maps.show-map-in-call-out',
  MAPS_HEDGE: 'maps.hedge',
  MAPS_RECYCLE_BIN: 'maps.recycle-bin',
  MAPS_BASEMAP_FIGURE: 'maps.basemap-figure',
  MAPS_SPATIAL_BOOKMARKS: 'maps.spatial-bookmarks',
  MAPS_BUFFER: 'maps.buffer',
  INTEGRATIONS_LOTSEARCH: 'integrations.lotsearch',
  INTEGRATIONS_API_KEYS: 'integrations.api-keys',
  INTEGRATIONS_BORE_DM: 'integrations.bore-dm',
  INTEGRATIONS_TAB_LOGS: 'integrations.tab-logs',
  GATHER_OFFLINE: 'gather.offline',
  GATHER_CHANGE_ICON_OPACITY: 'gather.change-icon-opacity',
  GATHER_SHARE_GROUPS_EDIT: 'gather.share-groups.edit',
  DATA_EVENTS: 'data-events',
  DATA_EVENTS_EDITING: 'data-events.edit',
  DATA_EVENTS_UNBRANDED_EMAILS: 'data-events.unbranded-emails',
  DATA_EVENTS_ATTACHMENTS: 'data-events.attachments',
  DOC_REVIEW: 'ai.document-review',
  FIGURE_PRINT_LAYOUTS: 'figure-print-layouts',
  CONFIDENTIAL_PROJECTS: 'confidential-projects',
  VECTOR_EDITOR: 'vector-editor',
  MANAGER_ONLY_INVITES: 'manager.restricted-invites',
  AI_EXPLORER: 'ai.explorer',
  DISPLACEMENT_GRAPH: 'data-insights.displacement-graph',
  PROJECT_FILTER_ASSIGNMENTS: 'project-filter.assignments',
  PROJECT_FILTER_HIDE_CONFIDENTIAL: 'project-filter.hide-confidential-projects',
  ENVIRO_LAYER_FILTER: 'enviro.layer-filter',
  BULK_EDITOR_GATHER_EDIT: 'bulk-editor.gather-edit',
} as const;

parseCachedAccess();

export {
  awaitAccess,
  clearAccessCache,
  FeatureKey,
  FEATURES,
  getAccessList,
  hasAccess,
  pretifyScopedFeatureSlug,
  setAccessList,
  setProjectId,
};
