<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import useLegacyRootBus from '../../composables/useLegacyRootBus';
import { GatherField, InputValue, Item } from '../../gather';
import { useProjectStore } from '../../store/project';
import { useCompanyUsersStore } from '../../store/company-users';
import { User } from '../../user';
import useAuth from '../../composables/useAuth';

const legacyRootBus = useLegacyRootBus();
const props = defineProps<{
  field: GatherField;
  item: Item;
  inputValue?: InputValue & { _localId?: string };
  isDisabled?: boolean;
}>();
const auth = useAuth();
const projectStore = useProjectStore();
const search = ref<string | null>(null);
const isMenuVisible = ref(false);
const dropdownInput = ref<HTMLInputElement>();
const searchInput = ref<HTMLInputElement>();
const companyUsersStore = useCompanyUsersStore();

const userOptions = computed<User[]>(() => {
  if (!props.field.options?.user_filter) {
    return projectStore.currentProject?.users || [];
  }
  const users: User[] = [];
  if (props.field.options.user_filter.includes('company')) {
    users.push(...companyUsersStore.companyUsers);
  }
  if (props.field.options.user_filter.includes('team')) {
    users.push(...(projectStore.currentProject?.users || []));
  }

  return users;
});

const authUser = computed(() => {
  return auth.user();
});

onMounted(async () => {
  if (!companyUsersStore.hasUsers && !companyUsersStore.isLoading) {
    await companyUsersStore.loadCompanyUsers();
  }

  let matchedUser: User | undefined;
  const isEditing = !!props.item.id;

  if (props.field.options?.has_auto_assign_creator && !isEditing) {
    matchedUser = userOptions.value.find(
      (u) => u.user_id === authUser.value?.user_id
    );
  }
  if (props.field.options?.has_auto_assign_editor && isEditing) {
    matchedUser = userOptions.value.find(
      (u) => u.user_id === authUser.value?.user_id
    );
  }

  if (matchedUser) {
    setValue(matchedUser);
  }
});

const searchInputPlaceholder = computed(() => {
  const is_add_enabled = props.field.options?.is_add_enabled;
  return !is_add_enabled
    ? 'Search options...'
    : 'Search options or add a new option...';
});

const userProperty = computed(() => {
  return props.field.options?.user_property || 'name';
});

const isSearchable = computed(() => {
  return userOptions.value?.length > 5;
});

const value = computed(() => {
  return props.inputValue?.value as string | undefined;
});

const value2 = computed<number | undefined>(() => {
  return props.inputValue?.value2
    ? parseInt(props.inputValue?.value2 as string)
    : undefined;
});

const searchResult = computed(() => {
  return search.value
    ? userOptions.value.filter((o) => {
        const s = search.value?.toLowerCase() || '';
        return (
          o.name?.toLowerCase().includes(s) || o.email.toLowerCase().includes(s)
        );
      })
    : userOptions.value;
});

function removeFocus() {
  dropdownInput.value?.blur();
}

async function toggleMenu() {
  isMenuVisible.value = !isMenuVisible.value;
  if (isMenuVisible.value) {
    await nextTick();
    searchInput.value?.focus();
  }
}

function hideMenu() {
  isMenuVisible.value = false;
}

function setValue(user: User) {
  save(user.user_id, user[userProperty.value]);
  hideMenu();
  search.value = null;
}

function selectOption(user: User) {
  save(user.user_id, user[userProperty.value]);
  toggleMenu();
}

function save(userId: number, property: string) {
  if (!props.inputValue) {
    throw new Error('User field: Input value is required');
  }
  legacyRootBus.$emit('updateInputValue', {
    inputValue: { ...props.inputValue, value: property, value2: userId },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
  });
}

function getUserProperty(user_id: number) {
  const user = userOptions.value.find((u) => u.user_id === user_id);
  if (!user) {
    return undefined;
  }
  console.log('userProperty.value', userProperty.value, user);
  return user[userProperty.value];
}
</script>

<template>
  <div
    v-click-outside="hideMenu"
    class="form-group"
    :id="`dropdown-${field.id}-${inputValue?._localId}`"
  >
    <label class="form-label" for="dropdown">
      {{ field.label }}
      <sup v-if="!!field.is_required" class="text-danger">*</sup>
    </label>

    <div class="input-group flex-nowrap">
      <input
        type="text"
        class="form-control cursor-pointer"
        ref="dropdownInput"
        :class="{
          'border-danger': !!field.is_required && !value,
          'rounded-bottom-0': isMenuVisible,
        }"
        name="dropdown-replace"
        :value="value2 ? getUserProperty(value2) || '' : ''"
        :required="!!field.is_required"
        :disabled="isDisabled"
        autocomplete="off"
        @focus="removeFocus"
        @click.prevent="toggleMenu"
      />
      <a class="btn border" :class="{ 'rounded-bottom-0': isMenuVisible }">
        <i
          class="fas"
          :class="isMenuVisible ? 'fa-caret-up' : 'fa-caret-down'"
        ></i>
      </a>
    </div>

    <div v-if="isMenuVisible" class="dropdown-content border border-top-0 p-2">
      <input
        v-if="isSearchable"
        ref="searchInput"
        type="text"
        class="form-control mb-2"
        v-model.trim="search"
        :placeholder="searchInputPlaceholder"
      />
      <div
        v-for="(user, optionIndex) in searchResult"
        class="d-flex align-items-center dropdown-option clickable btn text-start btn-light rounded-0"
        :key="optionIndex"
        @click.stop="selectOption(user)"
      >
        <a class="flex-grow-1">{{ user.name }} ({{ user.email }})</a>
      </div>
      <div v-if="searchResult && !searchResult.length" class="text-muted my-2">
        {{
          search?.length
            ? 'No users found for "' + search + '"'
            : 'No users found'
        }}
      </div>
    </div>
  </div>
</template>
