import axios, { AxiosRequestConfig, AxiosResponse, type Axios } from 'axios';
import { DATANEST_URL } from './env';

export const client: Axios = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const clientPublic: Axios = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});

type Interceptors = {
  doBeforeRequestIsSent:
    | ((
        value: AxiosRequestConfig<any>
      ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>)
    | undefined;
  handleRequestError: ((error: any) => any) | undefined;
  doAfterResponseIsReady:
    | ((
        value: AxiosResponse<any, any>
      ) => AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>)
    | undefined;
  handleResponseError: ((error: any) => any) | undefined;
};

export function setInterceptors(interceptors: Interceptors) {
  const {
    doBeforeRequestIsSent,
    handleRequestError,
    doAfterResponseIsReady,
    handleResponseError,
  } = interceptors;

  client.interceptors.request.use(doBeforeRequestIsSent, handleRequestError);
  client.interceptors.response.use(doAfterResponseIsReady, handleResponseError);

  axios.interceptors.request.use(doBeforeRequestIsSent, handleRequestError);
  axios.interceptors.response.use(doAfterResponseIsReady, handleResponseError);
}

export default function useApi(): Axios {
  return client!;
}

export function usePublicApi() {
  return clientPublic;
}

// Get the base url with fallbacks for both Gather and Datanest
export function getBaseUrl() {
  // explicit hub api for future use.
  if (import.meta.env?.VITE_HUB_API_URL) {
    return import.meta.env.VITE_HUB_API_URL;
  }
  if (!DATANEST_URL) {
    throw new Error('No DATANEST_URL found, missing env configuration?');
  }
  // If running in local container
  if (
    typeof window !== 'undefined' &&
    (window.location?.host || '').includes('nginx-datanest')
  ) {
    return '/api';
  }
  return `${DATANEST_URL}/api`;
}
